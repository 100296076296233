@import "~bootstrap/scss/bootstrap";
@import "~angular-calendar/css/angular-calendar";

/* grid columns xxl */

//o-bless Variables
:root {
  --ob-title-bg: #CADCFF;
  --ob-placeholder: rgba(42, 63, 81, 0.5);
  --of-white: #fff;
  --of-focus-color: #007bff9c;
}

// new colors
:root {
  --of-main-color: #0a2477;
  --of-second-color: #0199ff;
  --of-text-color: #656565;
  --of-white: #fff;
  --of-bg-color: #fff8f8;
}

@mixin placeholder {
  ::-webkit-input-placeholder {
    @content
  }

  :-moz-placeholder {
    @content
  }

  ::-moz-placeholder {
    @content
  }

  :-ms-input-placeholder {
    @content
  }
}


$linkcolour: #0199ff;

@media (max-width: 1280px) {
  .modal_cancel_btn {
    padding: 8px 15px !important;
    height: auto !important;
  }

  .alredy_done_div .input_textx {
    height: 39px !important;
  }
}

@media (min-width: 1600px) {
  .col-xxl {
    flex-basis: 0 !important;
    flex-grow: 1 !important;
    max-width: 100% !important;
  }

  .col-xxl-auto {
    flex: 0 0 auto !important;
    width: auto !important;
    max-width: 100% !important;
  }

  .col-xxl-1 {
    flex: 0 0 auto !important;
    max-width: 8.33333333% !important;
  }

  .col-xxl-2 {
    flex: 0 0 auto !important;
    max-width: 16.66666667% !important;
  }

  .col-xxl-3 {
    flex: 0 0 auto !important;
    max-width: 25% !important;
    width: 100% !important;
  }

  .col-xxl-4 {
    flex: 0 0 33.3333333333% !important;
    max-width: 33.3333333333% !important;
  }

  .col-xxl-5 {
    flex: 0 0 auto !important;
    max-width: 41.66666667% !important;
  }

  .col-xxl-6 {
    flex: 0 0 auto !important;
    max-width: 50% !important;
  }

  .col-xxl-7 {
    flex: 0 0 auto !important;
    max-width: 58.33333333% !important;
  }

  .col-xxl-8 {
    flex: 0 0 auto !important;
    max-width: 66.66666667% !important;
  }

  .col-xxl-9 {
    flex: 0 0 auto !important;
    max-width: 75% !important;
  }

  .col-xxl-10 {
    flex: 0 0 auto !important;
    max-width: 83.33333333% !important;
  }

  .col-xxl-11 {
    flex: 0 0 auto !important;
    max-width: 91.66666667% !important;
  }

  .col-xxl-12 {
    flex: 0 0 auto !important;
    max-width: 100% !important;
  }
}

// @import 'assets/css/theme-settings.css';
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;0,800;1,300;1,400;1,600&display=swap");

* {
  margin: 0px;
  padding: 0px;
}

$color_main: #f87405;
$color_alter: #334272;
$color_default: #161616;
$color_white: #ffffff;

html,
body {
  height: 100%;
  color: var(--main-color);
  font-family: "Open Sans", sans-serif;
  font-size: var(--clamp16);
}

::ng-deep body {
  overflow-y: auto;
  height: auto;
}

::ng-deep html {
  overflow-y: auto;
  height: auto;
}

body{
  font: 400 14px/20px "Open Sans", sans-serif!important;
}

.sideNav .mat-drawer-inner-container {
  overflow: initial;
  // margin-bottom: 40px;
}

.ng2-pdf-viewer-container {
  height: 300px !important;
  width: 100% !important;
}

html,
body {
  height: 100%;
  overflow: hidden;
}

a {
  cursor: pointer;
}

.mat-drawer.mat-drawer-side {
  @media screen and (min-width: 1025px) {
    z-index: 1 !important;
  }
}

.main-color {
  color: var(--main-color);
}

.hover-items.mat-menu-item,
.notify-wrap.mat-menu-item {
  min-width: 180px !important;
  color: var(--main-color);
  line-height: 45px;
  height: 48px;
  font-size: clamp(15px, 0.8333vw, 16px);
  font-family: "Open Sans", sans-serif !important;
  // box-shadow: 3px 5px 6px 3px rgb(0 0 0 / 20%), 0px 0px 0px 0px rgb(0 0 0 / 0%), 0px 0px 3px 0px rgb(0 0 0 / 0%) !important;
  background: rgb(255 255 255 / 100%) !important;
  // border-radius: 5px;
  margin-bottom: 0px;

  &:hover {
    background-color: #f0f5ff !important;
    font-weight: 600;
  }

  img {
    padding-right: 10px;
    width: 30px;
  }
}

.btn-custom {
  transition: 0.4s;
  color: #fff;
  border-radius: 3px;

  &:hover {
    color: var(--main-color);
  }
}

.wid20 {
  width: 20px !important;
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }

  50% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0% 50%;
  }
}

// LOGIN PAGES CSS
.go-logins {
  @media screen and (max-width: 767px) {
    background-size: initial;
    background-position: 60% bottom;
  }

  @media screen and (max-width: 1920px) and (min-width: 1440px) {
    display: table;
    width: 100%;
  }

  &::before {
    content: "";
    background: radial-gradient(72.92% 173.32% at 9.71% -40.53%,
        rgba(111, 255, 227, 0.5) 0%,
        rgba(111, 255, 227, 0.005) 89.48%),
      radial-gradient(60.42% 165.53% at 14.5% 93.07%,
        #ffecaa 18.3%,
        rgba(234, 239, 250, 0) 100%),
      radial-gradient(60.19% 93.98% at 57.44% -40.09%,
        #aceafe 6.8%,
        rgba(172, 234, 254, 0) 100%),
      #f4f9fd;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    animation: gradient 5s linear infinite;
    animation-direction: alternate;
    background-size: 600% 100%;

    @media screen and (max-width: 767px) {
      // background-color: transparent;
      opacity: 1;
      background: radial-gradient(80.62% 80.48% at 48.38% -7.11%,
          white 0%,
          rgba(255, 255, 255, 0.6) 49.8%,
          rgba(255, 255, 255, 0) 100%),
        radial-gradient(100% 100% at 0% 0%,
          rgba(255, 255, 255, 0.4) 0%,
          rgba(255, 255, 255, 0) 100%);
    }
  }

  .go-login-inn {

    // overflow: auto;
    // height: 100%;
    &::-webkit-scrollbar {
      width: 0.7rem;
    }

    &::-webkit-scrollbar-thumb {
      background: linear-gradient(180deg, #0064e65e 0%, #1637856b 100%);
      box-sizing: border-box;
      box-shadow: inset 0px 10px 10px rgba(255, 255, 255, 0.25);
      border-radius: 3px;
    }

    .title-head {
      text-align: center;
      padding-top: 0px;

      h1 {
        color: #1c3aa9;
        font-size: clamp(28px, 1.875vw, 36px);
        letter-spacing: 2px;
        font-weight: bold;
        line-height: 1.3;
        margin-top: 31px;
        text-transform: uppercase;
      }
    }

    .img-wrap {
      text-align: center;
      margin: auto;
      justify-content: center;

      img {
        // width: 75%;
        padding: 2px;
        // height: 100vh;
        height: 91vh;
      }
    }

    .form-layout {
      @media screen and (min-width: 1500px) {
        // padding-left: 50px;
        margin-right: 50px;
      }

      .form-wrap {
        @media screen and (max-width: 1024px) {
          padding-top: 40px;
        }

        @media screen and (max-width: 380px) {
          padding-top: 20px;
        }

        .logo-main {
          text-align: center;
          margin-top: 0;

          img {
            width: 420px;

            @media screen and (max-width: 1600px) {
              max-width: 250px;
            }

            @media screen and (max-width: 1250px) {
              max-width: 230px;
            }

            @media screen and (max-width: 540px) {
              max-width: 250px;
            }
          }
        }

        .welcome-wrap {
          // background: linear-gradient(134.68deg, #ffffffa6 1.45%, rgba(255, 255, 255, 0.302) 98.5%);
          background: #edfbff !important;
          // border: 1px solid rgb(51, 66, 114, .2);
          box-sizing: border-box;
          // border-radius: 8px;
          text-align: center;
          padding: 25px 20px 25px;
          margin: 2rem auto 0;
          max-width: 900px;

          h4 {
            color: #001169;
            font-weight: 600;
            font-size: var(--clamp24);
          }

          p {
            color: #001169;
            line-height: 1.4;
            margin-bottom: 0;
            font-size: var(--clamp16);
          }

          @media screen and (max-width: 767px) {
            min-width: 280px;
          }

          img {
            width: 21px;
            margin-right: 5px;

            @media screen and (max-width: 1366px) {
              margin-right: 5px;
              width: 15px;
            }
          }
        }

        h2 {
          text-align: center;
          display: block;
          font-size: clamp(26px, 1.875vw, 36px);
          color: var(--main-color);
          margin-top: 4rem;
          margin-bottom: 0;

          @media screen and (max-width: 1600x) {
            margin-top: 30px;
          }

          @media screen and (max-width: 1500x) {
            margin-top: 4rem;
          }

          @media screen and (max-width: 992px) {
            margin-top: 0;
          }
        }

        h2 {
          text-align: center;
          display: block;
          font-size: clamp(20px, 1.25vw, 24px);
          color: var(--main-color);
          margin-top: 0px;
          margin-bottom: 0 !important;
          font-weight: 600;

          @media screen and (max-width: 1440px) {
            margin: 0px 0px 10px !important;
          }

          @media screen and (max-width: 992px) {
            margin-top: 0;
          }

          @media screen and (max-width: 540px) {
            margin-top: 1rem;
          }
        }

        P {
          font-size: clamp(14px, 0.8333vw, 16px);
        }

        form {
          padding: 28px 0 0;

          @media screen and (max-width: 380px) {
            padding-top: 20px;
          }

          .captcha-btn {
            border: 1px solid #7688c2;
            text-align: center;
            border-radius: 4px;
            display: flex;
            height: 50px;
            align-items: center;
            justify-content: center;

            @media screen and (max-width: 1366px) {
              height: 44px;
            }

            @media screen and (max-width: 1024px) {
              height: 42px;
            }

            @media screen and (max-width: 991px) {
              height: 40px;
            }

            img {
              padding: 0px !important;
              width: auto;
              margin: 0 auto;
              border: none;
            }

            img.form-control {
              height: 32px !important;
              margin-top: 4px;

              @media screen and (max-width: 360px) {
                height: 25px !important;
              }
            }
          }

          .form-group {
            margin-bottom: 1rem;
            position: relative;

            .reset-capt {
              position: absolute;
              right: -12px;
              top: 12px;
              color: var(--main-color);
              cursor: pointer;
              transition: all 0.3s;

              @media screen and (max-width: 1024px) {
                top: 7px;
              }

              &:hover {
                opacity: 0.7;
                transform: rotate(190deg);
              }

              i {
                font-size: clamp(18px, 1.14583vw, 22px);
              }
            }

            #eye-icon {
              position: absolute;
              right: 30px;
              top: 11px;
              font-size: 22px;
              cursor: pointer;
              // color: var(--main-color);
              color: #7688c2;
            }

            .err-txt {
              font-size: 12px;
              color: red;
              margin-top: 5px;
              margin-left: 0px;
              font-family: Open Sans, sans-serif !important;
            }

            input,
            select {
              color: var(--main-color);
              font-size: clamp(14px, 0.8333vw, 16px);
              padding: 0.475rem 0.72rem;
              height: 50px;
              border: 1px solid #7688c2 !important;
              font-weight: 600;
              border-radius: 4px;
              background: #fff !important;

              @media screen and (max-width: 1366px) {
                height: 44px;
              }

              @media screen and (max-width: 1024px) {
                height: 42px;
              }

              @media screen and (max-width: 991px) {
                height: 40px;
              }

              &::placeholder {
                color: #8c92ab !important;
                font-size: clamp(14px, 0.8333vw, 16px) !important;
              }
            }

            input {
              margin-right: 12px !important;
            }

            input::placeholder {
              color: #cdd1e1 !important;
            }

            span {
              position: absolute;
              right: 25px;
              top: 14px;
              color: var(--main-color);
              font-size: 0.8rem;
            }

            .forget-p {
              margin-top: 10px;

              a {
                color: var(--main-color);
                margin-left: 10px;
                font-size: clamp(13px, 0.8333vw, 16px);
              }
            }

            button.btn {
              width: 100%;
              color: var(--main-color);
              text-transform: capitalize !important;
              font-weight: 600 !important;
              justify-content: center;
              border: 0;

              @media screen and (max-width: 1366px) {}
            }
          }

          .reg-text {
            font-size: 0.9rem;

            label {
              color: $color_default;
            }

            a {
              color: $color_main;
              padding-left: 10px;
            }
          }
        }
      }

      .followups {
        display: flex;
        // background: rgb(255 255 255);
        // box-shadow: 9.5625px 11.475px 19.125px rgba(0, 0, 0, 0.05);
        border-radius: 0px 0px 30px 30px;
        padding: 0px;
        justify-content: center;
        align-items: center;

        // height: 80px;
        @media screen and (max-width: 767px) {
          background: none;
          background: transparent;
          box-shadow: none;
        }

        label {
          color: $color_alter;
          margin-bottom: 0;
          padding-right: 10px;
          font-size: clamp(14px, 0.8333vw, 16px);

          @media screen and (max-width: 1400px) {
            padding-right: 4px;
          }
        }

        ul {
          display: flex;
          margin-bottom: 0;

          li {
            list-style: none;
            margin: 0 3px;

            a {
              display: flex;
              justify-content: center;
              // width: 33px;
              text-decoration: none;
              transition: all 0.3s;
              width: 32px;
              height: 32px;
              background: rgba(217, 239, 255, 0.4);
              border: 1px solid rgba(255, 255, 255, 0.5);
              box-sizing: border-box;
              box-shadow: inset 0px 10px 10px rgb(255 255 255 / 25%);
              backdrop-filter: blur(50px);
              border-radius: 50%;

              @media screen and (max-width: 1400) {
                width: 30px;
                height: 30px;
              }

              img {
                width: 18px;
              }

              &:hover {
                opacity: 0.5;
              }
            }
          }
        }
      }
    }
  }
}

// END
input,
select,
.custom-file-label {
  &:focus {
    outline: none !important;
    box-shadow: none !important;
  }

  &::placeholder {
    color: var(--main-color) !important;
    opacity: 1 !important;
  }

  &:-ms-input-placeholder {
    color: var(--main-color) !important;
  }

  &::-ms-input-placeholder {
    color: var(--main-color) !important;
  }
}

.mat-form-field-flex {
  background: rgba(204, 194, 194, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.5);

  .mat-select-arrow {
    color: var(--main-color);
  }

  .mat-form-field-label {
    color: var(--main-color) !important;
  }

  .mat-form-field-ripple {
    color: var(--main-color) !important;
  }

  .mat-select-value {
    color: var(--main-color) !important;
  }

  .mat-form-field.mat-focused.mat-primary .mat-select-arrow {
    color: var(--main-color) !important;
  }
}

.menu_toggle {
  position: fixed !important;
  top: 15px;
  left: 0px;
  z-index: 99999;

  @media screen and (max-width: 1440px) {
    top: 12px;
  }

  &.grid-btn {
    left: 240px;

    @media screen and (max-width: 991px) {
      left: 5px;
    }

    @media screen and (max-width: 800px) {
      left: 10px;
    }
  }
}

.chat-bg.box-wrap.Mheight .mat-tab-body-wrapper {
  margin-top: 30px;
}

.chat-bg.box-wrap.Mheight .mat-expansion-panel {
  background: transparent !important;
}

.chat-bg.box-wrap.Mheight .mat-expansion-panel-header-title {
  color: var(--main-color);
}

.chat-bg.box-wrap.Mheight .mat-expansion-panel-header {
  padding: 0px 5px !important;
}

.chat-bg.box-wrap.Mheight .mat-expansion-panel-header-description,
.mat-expansion-indicator::after {
  color: rgb(255 255 255 / 54%);
}

.chat-bg.box-wrap.Mheight .mat-tab-label,
.box-bg-tbl .mat-tab-label {
  margin: 2px !important;
  width: 55px !important;
  min-width: 66px;
  height: 35px !important;
  background: rgba(255, 255, 255, 0.05);
  border: 1px solid rgba(255, 255, 255, 0.8);
  box-sizing: border-box;
  border-radius: 4px;
  color: var(--main-color);
  opacity: 1;
}

.chat-bg.box-wrap.Mheight .mat-tab-header {
  border: none;
}

.chat-bg.box-wrap.Mheight [mat-align-tabs="center"]>.mat-tab-header .mat-tab-labels {
  justify-content: flex-start;
}

.chat-bg.box-wrap.Mheight .mat-tab-group.mat-primary .mat-ink-bar,
.mat-tab-nav-bar.mat-primary .mat-ink-bar {
  display: none;
}

.chat-bg.box-wrap.Mheight .mat-ripple.mat-tab-label.mat-focus-indicator.mat-tab-label-active,
.box-bg-tbl .mat-ripple.mat-tab-label.mat-focus-indicator.mat-tab-label-active {
  background: linear-gradient(180deg,
      rgba(255, 255, 255, 0.1) 0%,
      rgba(255, 255, 255, 0) 100%),
    linear-gradient(180deg, #ff9241 0%, rgba(255, 219, 0, 0.8) 100%);
  border: 1px solid rgba(255, 255, 255, 0.5);
  box-sizing: border-box;
  border-radius: 4px;
}

.menu_toggle mat-icon {
  line-height: 10px !important;

  @media screen and (max-width: 1024px) {
    line-height: 18px !important;
  }

  i {
    color: #1f4393 !important;
  }
}

// .slick-slide.img-wrap{margin: 0 5px;}
.body_content {
  display: inline-block;
}

.mat-drawer-transition .mat-drawer-content {
  @media screen and (max-width: 1024px) {
    margin-left: 85px !important;
  }

  @media screen and (max-width: 991px) {
    margin-left: 0 !important;
  }
}

mat-drawer {
  @media screen and (max-width: 1024px) {
    // background: rgb(255 255 255 / 80%) !important;
    // box-shadow: 5px 1px 5px -3px #051c6787;
  }
}

.main_content {
  height: calc(100vh - 104px);

  // height: calc(100% - 70px); for bottom edge
  // padding-bottom: 30px; for bottom edge
  @media screen and (max-width: 640px) {
    height: calc(100% - 145px);
  }

  &::-webkit-scrollbar {
    width: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background: linear-gradient(180deg, #0064e65e 0%, #1637856b 100%);
    box-sizing: border-box;
    box-shadow: inset 0px 10px 10px rgba(255, 255, 255, 0.25);
    border-radius: 2px;
  }

  .mat-drawer[style*="visibility: hidden"] {
    display: inherit !important;
  }
}

.links_content {
  height: calc(100% - 0px);
  overflow: auto;

  &::-webkit-scrollbar {
    width: 0.7rem;
  }

  &::-webkit-scrollbar-thumb {
    background: linear-gradient(180deg, #0064e65e 0%, #1637856b 100%);
    box-sizing: border-box;
    box-shadow: inset 0px 10px 10px rgba(255, 255, 255, 0.25);
    border-radius: 3px;
  }
}

// .timer-txt {
//   text-align: center;
//   margin-top: 10px;
//   display: block;
//   // color: #37AC00;
//   strong {
//     color: #37ac00;
//     font-weight: 600;
//   }
// }

/* Importing Bootstrap SCSS file. */

@import "~bootstrap/scss/bootstrap";

// otp styles
ng-otp-input {
  .otpContainer {
    display: flex;
    justify-content: center;

    .otpInput {
      // width: 100%;
      background: linear-gradient(134.68deg,
          rgba(255, 255, 255, 0.651) 1.45%,
          rgba(255, 255, 255, 0.302) 98.5%);
      // height: 40px;
      border-radius: 0;
      width: 70px !important;
      height: 70px !important;
      font-size: clamp(26px, 2.5vw, 48px) !important;

      @media screen and (max-width: 1366px) {
        width: 50px !important;
        height: 50px !important;
      }

      @media screen and (max-width: 360px) {
        width: 40px !important;
        height: 40px !important;
      }
    }
  }
}

.btm-calender {

  table thead tr,
  .table tbody tr {
    height: 0 !important;
  }
}

table thead tr,
.table tbody tr {
  height: 42px !important;

  @media screen and (max-width: 1024px) {
    height: 38px !important;
  }
}

.table {
  border-collapse: separate;
  border-spacing: 0 10px !important;
  background: none !important;
  width: 100%;

  thead {
    tr {
      border-radius: 8px;
      overflow: hidden;
      height: inherit;

      th {
        color: white !important;
        font-size: var(--clamp16) !important;
        text-transform: capitalize;
        border: none;

        // padding: 20px 10px;
        &:last-child {
          border-right: 1px solid rgba(255, 255, 255, 0.5);
          border-radius: 0px 4px 0px 0px;
        }

        &:first-child {
          border-left: 1px solid rgba(255, 255, 255, 0.5);
          border-radius: 4px 0px 0px 0px;
        }
      }

      h3 {
        margin: 0px;
        font-size: clamp(14px, 0.8333vw, 16px);
        line-height: clamp(20px, 1.45833vw, 28px);
      }
    }
  }

  tbody {
    tr {
      border-radius: 4px;

      td {
        border: none;
        // padding: 20px 10px;
        color: var(--main-color) !important;
        font-size: clamp(14px, 0.8333vw, 16px);

        &:last-child {
          border-right: 1px solid rgba(255, 255, 255, 0.3);
          border-radius: 0px 8px 8px 0px;
        }

        &:first-child {
          border-left: 1px solid rgba(255, 255, 255, 0.3);
          border-radius: 8px 0px 0px 8px;
        }

        i {
          font-size: 20px;
          color: var(--main-color);
          margin: 0px 5px;
        }

        .pending i {
          color: orange;
        }
      }
    }
  }

  .mat-sort-header-stem {
    display: none;
  }

  .mat-sort-header-indicator {
    display: none;
  }
}

.table-bordered th,
.table-bordered td {
  border: 0px solid #ffffff00 !important;
}

.mat-paginator {
  background: #fff !important;
}

.mat-paginator,
.mat-paginator-page-size .mat-select-trigger {
  color: var(--main-color) !important;
}

.mat-form-field-appearance-legacy .mat-form-field-infix {
  padding: 0.8em 0.5rem !important;
}

.mat-form-field-infix {
  border-top: 0 solid transparent !important;
}

.mat-paginator-icon {
  fill: var(--main-color) !important; //filter: invert(1);
  cursor: pointer;
}

.mat-h3,
.mat-subheading-2,
.mat-typography h3 {
  font-size: 16px !important;
}

.cdk-virtual-scroll-content-wrapper {
  position: relative !important;
}

.du-frm .mat-form-field-flex {
  background: rgb(255 255 255 / 0%);
  border: 1px solid rgb(255 255 255 / 0%);
  box-sizing: border-box;
  border-radius: 4px;
}

.du-frm .mat-input-element {
  text-align: center;
  color: var(--main-color);
  height: 52px;
}

.du-frm label.mat-form-field-label {
  color: var(--main-color) !important;
  left: 10px;
}

.du-frm .mat-form-field-appearance-legacy .mat-form-field-underline {
  height: 0px;
}

.faq-accordian-main .mat-expansion-panel-body {
  padding: 20px !important;
  font-size: clamp(14px, 0.8333vw, 16px);
}

.faq-accordian-main .mat-expansion-panel-header-description,
.faq-accordian-main .mat-expansion-indicator::after {
  color: var(--color6) !important;
}

.faq-accordian-main {
  .mat-expansion-panel-header {
    .mat-expansion-indicator {
      transform: none !important;
    }

    .mat-expansion-indicator::after {
      height: 8px;
      width: 15px;
      background-image: url("/assets/images/form-arrow.png");
      background-size: cover;
      border: none;
      border-width: 0 !important;
      transform: rotate(0) !important;
    }

    &.mat-expanded {
      .mat-expansion-indicator::after {
        background-image: url("/assets/images/form-arrow.png");
        transform: rotate(-180deg) !important;
      }
    }
  }
}

.load_main_data {
  .cke_chrome {
    border: none;

    .cke_inner {
      background: transparent;
      border: 1px solid #a7c0f5;
      border-radius: 4px;
      background-color: var(--of-white) !important;

      .cke_top {
        border-bottom: none;
        background: transparent;
      }

      .cke_wysiwyg_frame,
      .cke_wysiwyg_div {
        background: rgb(255, 255, 255);
        box-sizing: border-box;
        border-radius: 4px;
        min-height: 100px;
      }

      .cke_bottom {
        background: #f1f6ff;
        border-top: 1px solid #cccccc2e;

        a.cke_button {
          filter: sepia(100%) hue-rotate(190deg) saturate(460%);
        }

        .cke_combo_text {
          color: #3c57bd !important;
        }

        .cke_combo_arrow {
          border-top: 3px solid #3b56ba !important;
        }

        .cke_toolbar_start+.cke_combo_off a.cke_combo_button:hover {
          margin-left: -4px;
        }

        .cke_combo_off a.cke_combo_button:hover {
          border: 1px solid transparent !important;
        }

        .cke_resizer_ltr {
          margin-right: -7px;
          border-width: 15px 15px 0 0;
          position: absolute;
          bottom: 0;
          right: 9px;
        }
      }

      a.cke_button_on {
        background: #ffffff1f;
        border: 1px #bcbcbc3d solid;
      }
    }

    a.cke_button_off:hover,
    a.cke_button_off:focus,
    a.cke_button_off:active {
      background: #e5e5e552;
      border: 1px #bcbcbc30 solid;
    }

    a.cke_combo_button {
      // border: 1px solid #fff;
      margin-right: 5px;
      border-radius: 3px;
      margin-left: -1px;

      &:hover {
        background: #e5e5e533;
        // border: 1px solid #ffffff;
        padding: 0px;
        margin-left: -1px;
      }
    }
  }
}

.compose-box-right {
  .cke_chrome {
    border: none;

    .cke_inner {

      // background: transparent;
      .cke_wysiwyg_frame,
      .cke_wysiwyg_div {
        background: transparent;
      }

      .cke_top {
        border-bottom: none;
        background: transparent;
      }

      box-shadow: inset 0px 10px 10px rgb(255 255 255 / 80%);
      background: rgba(255, 255, 255, 0.9);
      border-radius: 4px;
      box-sizing: border-box;
      color: var(--main-color);

      .cke_bottom {
        margin-top: 10px;
        background: transparent;
        border-top: 0;
      }
    }

    a.cke_button_off:hover,
    a.cke_button_off:focus,
    a.cke_button_off:active {
      background: #e5e5e552;
      border: 1px #bcbcbc30 solid;
    }

    .cke_combo_text {
      color: var(--main-color);
    }

    a.cke_combo_button {
      border: 1px solid #fff;
      margin-right: 5px;
      border-radius: 3px;
      margin-left: -1px;

      &:hover {
        background: #e5e5e533;
        border: 1px solid #ffffff;
        padding: 1px;
        margin-left: -1px;
      }
    }
  }
}

// .eml-tmp-btn .mat-tab-label.mat-tab-label-active {
//     border-radius: 6px;
//     color: #fff !important;
//     text-transform: uppercase;
//     font-weight: normal;
//     font-style: normal;
//     opacity: 1;
// }
// .eml-tmp-btn .mat-tab-label {
//     border-radius: 6px;
//     color: var(--main-color) !important;
//     text-transform: uppercase;
//     font-size: clamp(14px, 0.8333vw, 16px);
//     font-weight: normal;
//     font-style: normal;
//     opacity: 1;
//     border: 0;
//     border-radius: 8px 8px 0 0 !important;
// }
// .eml-tmp-btn .mat-tab-label:focus {
//     background: linear-gradient( 180deg, rgba(255, 255, 255, 0.1) 0%, rgba(255, 255, 255, 0) 100%), linear-gradient(180deg, #ff9241 0%, rgba(255, 219, 0, 0.8) 100%);
//     border: 1px solid rgba(255, 255, 255, 0.5);
//     border-radius: 6px;
//     box-shadow: inset 0px 10px 10px rgb(255 255 255 / 25%);
//     color: var(--main-color);
//     text-transform: uppercase;
//     font-weight: normal;
//     font-style: normal;
//     opacity: 1;
// }
.mat-tab-group.mat-primary .mat-ink-bar,
.mat-tab-nav-bar.mat-primary .mat-ink-bar {
  width: 0 !important;
}

.mat-tab-nav-bar,
.mat-tab-header {
  border-bottom: none !important;
}

// sidebar icons show styles
.mat-drawer {
  .mat-nav-list {
    a {
      span {
        display: none;
      }
    }
  }

  &.mat-drawer-opened {
    width: 310px;

    @media screen and (max-width: 1440px) {
      width: 260px;
    }

    @media screen and (max-width: 1024px) {
      background: #efefef;
    }

    a {
      span {
        display: inline-block !important;
        margin-left: 10px;
        font-size: clamp(15px, 0.9375vw, 18px);
      }
    }
  }
}

.app-container {
  .mat-drawer-content {
    &::-webkit-scrollbar {
      width: 10px;
    }

    &::-webkit-scrollbar-thumb {
      background: linear-gradient(180deg, #0064e65e 0%, #1637856b 100%);
      box-sizing: border-box;
      box-shadow: inset 0px 10px 10px rgba(255, 255, 255, 0.25);
      border-radius: 2px;
    }

    @media screen and (min-width: 992px) {
      margin-left: 85px;
    }
  }
}

// sidebar icons show styles end
.mat-dialog-container {
  // background: linear-gradient(111.87deg, #0034ec 31.94%, #1d5cff 100.54%);
  // border: 1px solid rgba(255, 255, 255, 0.5);
  // box-sizing: border-box;
  // box-shadow: inset 0px 10px 10px rgb(255 255 255 / 25%);
  // border-radius: 4px;
  background: rgba(255, 255, 255, 0.8);
  border: 1.5px solid rgba(255, 255, 255, 0.8);
  box-shadow: inset 0px 10px 20px rgb(255 255 255 / 80%);
  box-sizing: border-box;
  border-radius: 5px;
}

// video player modal styles start
.modal-content {
  // background-color: #bfbfbf !important;
  background-color: #ffffff !important;
  border-radius: 8px;
  border: none;

  .modal-header {
    h4 {
      font-weight: 600;
      font-size: var(--clamp16);
      padding-bottom: 5px;
      color: white;
    }

    .close {
      // background: rgba(255, 255, 255, 0.3);
      border: none !important;
      box-sizing: border-box;
      box-shadow: none;
      backdrop-filter: blur(10px);
      border-radius: 100%;
      padding: 0;
      // width: 25px;
      // height: 25px;
      color: white;
      opacity: 1;
      line-height: 0;
      outline: none;
      margin: 0;
      margin-bottom: 10px;
      text-shadow: none;
    }

    p {
      font-size: var(--clamp16);
      margin: 0;
      color: white;
      font-weight: normal;
      font-style: normal;
    }
  }
}

.modal-content .pop-up .modal-header h4 {
  color: white;
}

// video player modal styles end
.webiner_div_back {
  img {
    vertical-align: sub;
    padding-right: 5px;
    width: 100%;
  }
}

.video-player-model {
  .modal-body {
    padding: 0;
    position: relative;

    .close {
      position: absolute;
      top: -16px;
      width: 36px;
      height: 36px;
      background-color: var(--color2);
      z-index: 999;
      color: #fff;
      opacity: 1 !important;
      border-radius: 100%;
      right: -16px;
    }
  }
}

.btm-calender {
  .mat-card {
    padding: 0 !important;
    background: #fff0 !important;
    box-shadow: none !important;
  }

  table tbody tr {
    height: 20px !important;
  }

  .mat-calendar-controls {
    display: flex;
    margin: 0% calc(33% / 7 - 16px);
  }

  .mat-calendar-header {
    padding: 0px 8px 0 8px;
  }

  .mat-calendar-body-cell-content {
    height: 60%;
    top: 0%;
  }
}

.calendar-widget {
  .mat-calendar-body-cell {
    padding: 17.7px 0 !important;
  }
}

// .h45 {
//   height: 44px !important;
//   @media screen and (max-width: 1366px) {
//     height: 40px !important;
//   }
// }
.notify-wrap {
  height: 60px !important;

  .notify-wrap-inn {
    align-items: center;

    .noti-img {
      position: relative;

      img {
        width: 40px;
        border-radius: 5px;
        padding: 0;
      }

      span {
        position: absolute;
        display: block;
        top: -10px;
        left: 20px;
        z-index: 99;

        i {
          background: var(--color2);
          width: 30px;
          height: 30px;
          border-radius: 50px;
          text-align: center;
          color: #fff;
          padding: 7px;
        }

        img {
          width: 30px;
          border-radius: 50px;
        }
      }
    }

    .noti-names {
      display: block;
      font-size: clamp(14px, 0.8333vw, 16px);
      line-height: 1;
      padding-left: 20px;

      span {
        font-size: 13px;
        display: block;
        margin-top: 5px;
        overflow: hidden;
        width: 170px;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }
}

.theme-set-wrap {
  p {
    line-height: 2;
    margin-bottom: 0;
  }

  .theme-btns {
    line-height: 1;
    align-items: center;
    display: flex;

    a#theme-default {
      background: linear-gradient(270.25deg, #fefce6 0.23%, #cee3fe 97.03%);
    }

    a#theme-one {
      background: linear-gradient(0deg, #ddf9f3, #ddf9f3),
        linear-gradient(248.22deg, #caeaf4 4.46%, #fff5ca 94.23%);
    }

    a#theme-two {
      background: #fff2ee;
    }

    a#theme-three {
      background: #e6efff;
    }

    a#theme-four {
      background: #e0f6ff;
    }

    a#theme-five {
      background: #ffeef1;
    }

    a#theme-six {
      background: linear-gradient(0deg,
          rgba(255, 255, 255, 0.3),
          rgba(255, 255, 255, 0.3)),
        linear-gradient(248.22deg, #caeaf4 4.46%, #fff5ca 94.23%);
    }

    a#theme-seven {
      background: linear-gradient(224.76deg, #fffbec 0%, #ffe1e1 76.88%);
    }

    a#theme-eight {
      background: linear-gradient(90deg, #ffebe8 3.68%, #e7e1ff 98.94%);
    }

    a#theme-nine {
      background: linear-gradient(90deg, #cce3ff 0%, #f1ffde 100%);
    }

    a#theme-ten {
      background: linear-gradient(270.25deg, #dbe3ff 0.23%, #fef1f5 99.79%);
    }

    a#theme-eleven {
      background: url(assets/images/themes/pattern/1_Pattern.svg);
    }

    a#theme-twelve {
      background: url(assets/images/themes/pattern/2_Pattern.svg);
    }

    a#theme-thirteen {
      background: url(assets/images/themes/pattern/3_Pattern.svg);
    }

    a#theme-fourteen {
      background: url(assets/images/themes/pattern/4_Pattern.svg);
    }

    a#theme-fifteen {
      background: url(assets/images/themes/pattern/5_Pattern.svg);
    }

    a#theme-sixteen {
      background: url(assets/images/themes/abstract/1_abstract.svg);
    }

    a#theme-seventeen {
      background: url(assets/images/themes/abstract/2_abstract.svg);
    }

    a#theme-eighteen {
      background: url(assets/images/themes/abstract/3_abstract.svg);
    }

    a#theme-nineteen {
      background: url(assets/images/themes/abstract/4_abstract.svg);
    }

    a#theme-twenty {
      background: url(assets/images/themes/abstract/5_abstract.svg);
    }

    a#theme-twenty-one {
      background: url(assets/images/themes/bubble/1_Bubble.jpg);
    }

    a#theme-twenty-two {
      background: url(assets/images/themes/bubble/2_Bubble.jpg);
    }

    a#theme-twenty-three {
      background: url(assets/images/themes/bubble/3_Bubble.jpg);
    }

    a#theme-twenty-four {
      background: url(assets/images/themes/bubble/4_Bubble.jpg);
    }

    a#theme-twenty-five {
      background: url(assets/images/themes/bubble/5_Bubble.jpg);
    }

    a#theme-twenty-six {
      background: url(assets/images/themes/nature/1_Nature.jpg);
    }

    a#theme-twenty-seven {
      background: url(assets/images/themes/nature/2_Nature.jpg);
    }

    a#theme-twenty-eight {
      background: url(assets/images/themes/nature/3_Nature.jpg);
    }

    a#theme-twenty-nine {
      background: url(assets/images/themes/nature/4_Nature.jpg);
    }

    a#theme-thirty {
      background: url(assets/images/themes/nature/5_Nature.jpg);
    }
  }
}

.socail-wrap {
  display: none;

  @media screen and (max-width: 767px) {
    display: flex;
  }
}

.search-results {
  height: calc(100vh - 110px);
  overflow-y: auto;
  overflow-x: hidden;
  margin-top: -20px;
  padding-top: 20px;

  @media screen and (max-width: 1366px) {
    height: calc(100vh - 110px);
  }

  @media screen and (max-width: 640px) {
    height: calc(100vh - 220px);
  }

  &::-webkit-scrollbar {
    width: 10px;

    @media screen and (max-width: 992px) {
      width: 2px;
    }
  }

  &::-webkit-scrollbar-thumb {
    background: linear-gradient(180deg, #0064e65e 0%, #1637856b 100%);
    box-sizing: border-box;
    box-shadow: inset 0px 10px 10px rgba(255, 255, 255, 0.25);
    border-radius: 2px;
  }
}

.logo-new {
  img {
    width: 31%;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
  }
}

.logo.is-animetion {
  img {
    width: 42%;
    position: absolute;
    right: 0;
    top: 6px;
  }

  span {
    display: inline-block;
    animation: wave-text 1s ease-in-out infinite;
    font-size: 11px !important;
    font-weight: 500 !important;
    margin-left: 0 !important;
    color: var(--color2);
    text-shadow: 1px 2px 2px #b1b1b1;
  }
}

.logo.is-animetion {
  span:nth-of-type(1) {
    animation-delay: 0s;
  }

  span:nth-of-type(2) {
    animation-delay: 0.1s;
  }

  span:nth-of-type(3) {
    animation-delay: 0.2s;
  }

  span:nth-of-type(4) {
    animation-delay: 0.3s;
  }

  span:nth-of-type(5) {
    animation-delay: 0.4s;
  }

  span:nth-of-type(6) {
    animation-delay: 0.5s;
  }

  span:nth-of-type(7) {
    animation-delay: 0.6s;
  }

  span:nth-of-type(8) {
    animation-delay: 0.7s;
  }

  span:nth-of-type(9) {
    animation-delay: 0.8s;
  }

  span:nth-of-type(10) {
    animation-delay: 0.9s;
  }
}

@keyframes wave-text {
  00% {
    transform: translateY(0em);
  }

  60% {
    transform: translateY(-0.6em);
  }

  100% {
    transform: translateY(0em);
  }
}

.mail-n {
  a {
    margin-left: 20px;
  }

  .custom-btn-outline.border-none {
    border: none;
  }
}

.my-invite {
  h2 {
    font-family: Open Sans, sans-serif !important;
    font-weight: 600 !important;
    font-size: 24px;
    line-height: 33px;
    color: var(--main-color);
  }
}

.team-summary-details {
  h2 {
    font-family: Open Sans, sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 33px;
    color: var(--main-color);
    margin-bottom: 0px;
  }
}

.multi_slides_ebook {
  .slick-slider {
    position: relative;

    .slick-arrow {
      box-shadow: none;
      border: none;
      position: absolute;
      top: 20%;
      color: var(--main-color);
      font-size: 24px;
      background-color: transparent;

      &.slick-prev {
        left: -35px;
      }

      &.slick-next {
        right: -35px;
      }
    }

    .active {
      .img-circle {
        transform: scale(1.2);
        box-shadow: 0px 0px 5px #2196f3;
      }

      h4 {
        // color: var(--color2) !important;
        border-bottom: 2px solid var(--color2);
        display: inline-block;
        padding-bottom: 4px;
        font-weight: bold !important;
      }
    }
  }
}

.field.StripeElement {
  border-radius: 8px !important;
  padding: 0px 10px !important;
  background-color: #fff !important;
  margin-bottom: 15px !important;
}

.ElementsApp input {
  padding: 10px !important;
}

.inbox-table th.mat-header-cell,
.inbox-table td.mat-cell,
.inbox-table td.mat-footer-cell {
  padding: 0.75rem 1.5rem !important;
}

.read-mail-wrap {
  background: rgba(255, 255, 255, 0.6) !important;
  margin-top: 30px;
  border: none !important;

  .reply-mail-wrap {
    .load_main_data {
      .cke_inner {

        #cke_17,
        #cke_24,
        #cke_32,
        #cke_37,
        #cke_70,
        #cke_84,
        #cke_87,
        #cke_90,
        #cke_74 {
          display: none !important;
        }
      }
    }
  }
}

// .modal.show .modal-dialog {
//     width: 94%;
//     transform: translate(-50%, -50%);
//     position: absolute;
//     top: 50%;
//     left: 50%;
//     margin: auto;
// }
.modal {
  z-index: 20;

  .modal-dialog {
    margin-top: 60px;
    margin-bottom: 60px;
  }
}

.modal-backdrop {
  z-index: 15 !important;
}

.user-photo .avatar-container {
  @media screen and (min-width: 992px) {
    height: 100px !important;
    width: 100px !important;
  }

  @media screen and (max-width: 992px) {
    height: 70px !important;
    width: 70px !important;
  }
}

.user-photo .avatar-content {
  font-weight: 600 !important;
  font-size: 26px !important;
  align-items: center;
  display: flex;
  justify-content: center;
  border: 4px solid #ffffff;

  @media screen and (min-width: 992px) {
    height: 100px !important;
    width: 100px !important;
  }

  @media screen and (max-width: 992px) {
    height: 70px !important;
    width: 70px !important;
  }

  @media screen and (max-width: 1080px) {
    font-size: 20px !important;
    height: 70px;
    width: 70px;
  }

  @media screen and (max-width: 991px) {
    font-size: 20px !important;
    height: 70px;
    width: 70px;
  }

  @media screen and (max-width: 767px) {
    font-size: 26px !important;
    height: 80px;
    width: 80px;
  }
}

.btm-img .avatar-content {
  font-size: 15px !important;
  align-items: center;
  position: relative;
  justify-content: center;
  width: 42px !important;
  height: 42px !important;
  line-height: 40px !important;
}

.btm-img .avatar-container {
  width: 40px !important;
  height: 40px !important;
  align-items: center;
}

.tick_details {
  .avatar-container {
    display: inline-block;
  }
}

// community styles start
.community-main {
  &-top {
    margin-top: 30px;

    &-left {
      .profile {
        padding: 12px;
        text-align: center;
        width: 160px;

        @media screen and (max-width: 640px) {
          width: 100%;
        }

        .prof_container {
          margin: 0 auto;
        }

        h4 {
          font-size: clamp(16px, 0.9375vw, 18px);
          font-weight: 600;
          color: var(--main-color);
        }

        p {
          font-size: clamp(14px, 0.8333vw, 16px);
          color: var(--main-color);
        }
      }
    }

    &-right {
      .create_post {
        &-top {
          padding: 10px 24px;

          .pen-icon {
            background: linear-gradient(180deg,
                rgba(255, 255, 255, 0.1) 0%,
                rgba(255, 255, 255, 0) 100%),
              linear-gradient(180deg, rgba(255, 219, 0, 0.8) 0%, #ff9241 98.96%);
            width: 42px;
            height: 42px;
            text-align: center;
            line-height: 40px;
            border-radius: 100%;
            display: inline-block;

            img {
              max-width: 35px;
            }
          }

          textarea {
            background-color: #fff;
            min-height: 79px;
            padding: 15px;
            overflow: hidden;
            width: 100%;
            border: none;
            border-radius: 8px;

            &:focus {
              outline: none;
            }
          }

          .custom-select {
            border: none;
            height: 52px;
          }

          .hash-icon {
            a {
              color: var(--main-color);
              font-size: 24px;

              &:hover {
                border: none;
              }
            }
          }
        }

        &-bottom {
          background: radial-gradient(55.04% 70.76% at 84.64% -9.25%,
              rgba(139, 240, 220, 0.8) 0%,
              rgba(255, 255, 255, 0.008) 100%),
            radial-gradient(68.34% 101.91% at 100% 93.98%,
              #f5ffce 0%,
              rgba(234, 239, 250, 0) 100%),
            radial-gradient(71.34% 71.34% at 7.7% 100%,
              #f9e0ff 0%,
              rgba(227, 212, 231, 0) 100%),
            radial-gradient(75.39% 80.27% at 0% -2.09%,
              #cadcff 6.8%,
              rgba(202, 220, 255, 0) 100%),
            #f4f9fd;
          border: 1.5px solid rgba(255, 255, 255, 0.8);
          box-sizing: border-box;
          box-shadow: inset 0px 10px 20px rgba(255, 255, 255, 0.8);
          padding: 10px 24px;

          ul {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 0;

            @media screen and (max-width: 640px) {
              display: block;
            }

            li {
              list-style: none;

              @media screen and (max-width: 640px) {
                padding-bottom: 10px;
              }

              &:first-child {
                width: 110px;
              }

              a,
              label {
                color: var(--main-color);
                font-size: clamp(14px, 0.8333vw, 16px);
                display: flex;
                align-items: center;
                margin: 0;

                img {
                  margin-right: 4px;
                }

                @media screen and (max-width: 640px) {
                  font-size: 14px;
                }

                h4 {
                  font-size: clamp(16px, 0.9375vw, 18px);
                  font-weight: 600;
                  color: var(--main-color);
                  overflow: hidden;
                  text-overflow: ellipsis;
                  white-space: nowrap;
                }

                &:hover {
                  text-decoration: none;
                }
              }
            }
          }
        }
      }
    }
  }

  .news-feed-block {
    .grid-item {
      margin-bottom: 20px;

      .post_item {
        border: 1.5px solid #ffffff;
        border-radius: 8px;

        .post-header {
          padding: 24px 32px;

          ul {
            display: flex;
            margin-bottom: 0;
            align-items: center;

            li {
              list-style: none;
              margin-right: 12px;

              &.profile-img {
                .country-img {
                  position: absolute;
                  bottom: 0;
                  right: 0px;
                }
              }

              h4 {
                font-size: var(--clamp18);
                margin-bottom: 5px;
                font-weight: 600;
                color: var(--of-main-color);
              }

              p {
                font-size: var(--clamp16);
                margin-bottom: 0;
                color: var(--of-text-color);
              }

              span {
                font-size: 12px;

                @media screen and (max-width: 480px) {
                  font-size: 10px !important;
                }
              }

              .profile_pic {
                border-radius: 100%;
                margin-right: 12px;
              }
            }
          }
        }

        .content-text {
          padding: 0 32px;
          font-size: clamp(16px, 0.9375vw, 18px);
          color: var(--main-color);

          .post-text {
            font-size: var(--clamp18);
            color: var(--of-text-color);
          }

          ol {
            list-style: inside decimal;
          }

          ul {
            list-style: inside disc;
          }

          .emoji-pipe-image {
            position: relative;
            top: 3px;
          }
        }

        .post-footer {
          padding: 10px 32px;
          border-radius: 0;
          // position: relative;
          // z-index: 1;
          background-color: #e9e9e9;

          ul {
            margin: 0;
            display: flex;
            justify-content: space-between;
            width: 60%;

            @media screen and (max-width: 640px) {
              width: 100%;
            }

            li {
              list-style: none;

              a {
                display: flex;
                align-items: center;
                color: var(--main-color);
                font-weight: 600;
                font-size: var(--clamp16);

                &:hover {
                  text-decoration: none;
                }

                .comments_panel {
                  padding: 12px;

                  h3 {
                    color: var(--main-color);
                    font-weight: 600;
                    font-size: 20px;
                  }

                  .close {
                    color: var(--color2);
                  }

                  .parent_cmnt,
                  .child_cmnt {
                    textarea {
                      border: none;
                      min-height: 52px;
                      padding: 10px 80px 10px 15px;
                    }
                  }

                  .comment-box {
                    color: var(--main-color);
                    margin-bottom: 12px;

                    &-top {
                      padding: 12px;

                      .user-img {
                        margin-right: 5px;

                        img {
                          border-radius: 100%;
                        }
                      }

                      .prnt_user_title {
                        font-size: clamp(14px, 0.8333vw, 16px);
                        font-weight: 600;
                      }

                      span {
                        color: var(--main-color);
                      }
                    }

                    &-center {
                      padding: 12px;
                      color: var(--main-color);
                      font-size: clamp(14px, 0.8333vw, 16px);
                    }

                    &-bottom {
                      padding: 12px;

                      ul {
                        display: flex;
                        align-items: center;

                        li {
                          list-style: none;
                          color: var(--main-color);
                          font-size: clamp(14px, 0.8333vw, 16px);
                          font-weight: 600;
                          margin-right: 25px;
                        }
                      }
                    }
                  }
                }
              }
            }
          }

          .action-menu {
            .mat-button {
              color: var(--main-color);
              font-weight: 600;
              font-size: var(--clamp18);
              min-width: 50px;
              padding: 0;
            }
          }
        }

        .comments_panel {
          padding: 12px;

          h3 {
            color: var(--main-color);
            font-weight: 600;
            font-size: 20px;
          }

          .close {
            color: var(--color2);
          }

          .parent_cmnt,
          .child_cmnt {
            textarea {
              border: none;
              min-height: 52px;
              padding: 10px 80px 10px 15px;
            }
          }

          .comment-box {
            color: var(--main-color);
            margin-bottom: 12px;

            &-top {
              padding: 12px;

              .user-img {
                margin-right: 5px;

                img {
                  border-radius: 100%;
                }
              }

              .prnt_user_title {
                font-size: clamp(14px, 0.8333vw, 16px);
                font-weight: 600;
              }

              span {
                color: var(--main-color);
              }
            }

            &-center {
              padding: 12px;
              color: var(--main-color);
              font-size: clamp(14px, 0.8333vw, 16px);
            }

            &-bottom {
              padding: 12px;

              ul {
                display: flex;
                align-items: center;

                li {
                  list-style: none;
                  color: var(--main-color);
                  font-size: clamp(14px, 0.8333vw, 16px);
                  margin-right: 25px;
                  display: flex;
                  align-items: center;
                  gap: 5px;
                }
              }
            }
          }
        }
      }
    }

    .pagination-box {
      margin: 40px 0 100px 0;
      color: var(--main-color);
      font-size: clamp(14px, 0.8333vw, 16px);
      font-weight: 600;

      .ngx-pagination {
        li {
          span {
            color: var(--main-color);
          }

          &.current {
            background: linear-gradient(180deg,
                rgba(255, 255, 255, 0.1) 0%,
                rgba(255, 255, 255, 0) 100%),
              linear-gradient(180deg, rgba(255, 219, 0, 0.8) 0%, #ff9241 98.96%);
            color: #fff;
          }

          button,
          a {
            color: var(--color2);
          }
        }
      }
    }
  }
}

// community styles end
// languages select styles
.goog-te-gadget span {
  display: block !important;
  border-left: 0 !important;
  text-decoration: none !important;
  font-family: "Open Sans", sans-serif !important;
  text-transform: capitalize !important;
  color: var(--main-color) !important;
}

.goog-te-gadget img {
  display: none !important;
}

.goog-te-gadget-simple {
  border: 1px solid var(--main-color) !important;
  background: transparent !important;
  border-radius: 3px !important;
}

.goog-te-gadget-simple .goog-te-menu-value:hover {
  text-decoration: none !important;
}

.goog-te-gadget-simple .goog-te-menu-value span:last-child {
  padding-left: 20px !important;
  // display: none !important;
}

.goog-te-gadget-simple .goog-te-menu-value {
  display: flex;
  max-height: 20px;
  line-height: 20px;
}

.goog-te-menu-frame {
  margin: auto;
  top: 65px !important;
  left: 20px !important;
  right: 20px !important;
  overflow-y: scroll !important;
  width: 95% !important;
  // @media screen and (max-width: 1280px) {
  //     width: 800px !important;
  // }
  // @media screen and (max-width: 1024px) {
  //     width: 600px !important;
  // }
  // @media screen and (max-width: 768px) {
  //     width: 500px !important;
  // }
}

// end
.select-lang {
  font-size: 0;

  .goog-te-gadget {
    font-size: 1px;
    position: relative;
    color: transparent;

    // &:before {
    //     content: "\f107";
    //     font-family: "Font Awesome 5 Pro", sans-serif;;
    //     position: absolute;
    //     right: 16px;
    //     top: -14px;
    //     font-size: var(--clamp18);
    //     color: var(--main-color);
    //     z-index: 0;
    //     height: 10px;
    //     @media screen and (max-width: 460px) {
    //         top: -37px;
    //     }
    // }
  }

  select {
    // border: none;
    box-shadow: inset 0px -5px 5px rgba(255, 255, 255, 0.15),
      inset 0px 10px 10px rgba(255, 255, 255, 0.15);
    border-radius: 4px;
    background-color: transparent !important;
    height: 45px;
    color: var(--main-color) !important;
    border: 1px solid var(--main-color);
    font-size: clamp(14px, 0.8333vw, 16px);
    -webkit-appearance: none;
    -moz-appearance: none;
    padding: 0 15px;

    option {
      color: var(--main-color) !important;
    }
  }
}

// languages select end
table.pending-table-content .mat-sort-header-stem,
.closed-content table .mat-sort-header-stem {
  display: flex !important;
}

table.pending-table-content .mat-sort-header-indicator,
.closed-content table .mat-sort-header-indicator {
  display: block;
}

.mat-dialog-container {
  background: radial-gradient(55.04% 70.76% at 84.64% -9.25%,
      rgba(139, 240, 220, 0.8) 0%,
      rgba(255, 255, 255, 0.008) 100%),
    radial-gradient(68.34% 101.91% at 100% 93.98%,
      #f5ffce 0%,
      rgba(234, 239, 250, 0) 100%),
    radial-gradient(71.34% 71.34% at 7.7% 100%,
      #f9e0ff 0%,
      rgba(227, 212, 231, 0) 100%),
    radial-gradient(75.39% 80.27% at 0% -2.09%,
      #cadcff 6.8%,
      rgba(202, 220, 255, 0) 100%),
    #f4f9fd !important;
  border: 1px solid rgba(255, 255, 255, 0.5);
  box-sizing: border-box;
  box-shadow: inset 0px 10px 10px rgba(255, 255, 255, 0.25);
  position: relative;
  padding-top: 40px !important;

  .closebtn {
    background: #e84c3d;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: inherit;
    color: #fff;
    position: absolute;
    right: 20px;
    top: 15px;
    z-index: 9999;
    opacity: 1 !important;
  }
}

.leaderboard_popup {
  h2 {
    font-family: Open Sans, sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: clamp(18px, 1.25vw, 24px);
    line-height: 33px;
    color: #009c80;
    position: relative;
  }

  p {
    color: var(--main-color);
    font-size: clamp(14px, 833vw, 16px);
    margin-bottom: 20px;
  }
}

.my-team {

  .md-drppicker td.active,
  .md-drppicker td.active:hover {
    color: #fff !important;
  }
}

.lb-ani {
  right: 38px !important;
  top: 17px !important;
}

.mylinks-ani {
  right: 66px !important;
  top: 15px !important;
}

.mat-form-field-flex {
  background: transparent !important;
  border: none !important;
}

.mat-form-field-appearance-legacy .mat-form-field-underline {
  background: none !important;
}

.grp-name {
  .mat-form-field-appearance-legacy .mat-form-field-infix {
    padding: 0em 0.5em !important;
  }
}

.mat-tooltip {
  font-weight: 500;
  font-size: clamp(14px, 0.9375vw, 18px);
  line-height: clamp(24px, 1.254vw, 24px);
  white-space: pre-wrap;
  color: var(--main-color) !important;
  background-color: rgba(255, 255, 255, 0.9);
  border: 1px solid var(--main-color);
  max-width: unset !important;
}

.profile-pic .avatar-container {
  height: 100% !important;
  width: 100% !important;
}

.profile-pic .avatar-content {
  height: 100% !important;
  width: 100% !important;
  object-fit: cover;
  object-position: center;
  border-radius: 8px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: clamp(25px, 3.125vw, 60px) !important;
  font-weight: bold !important;
  font-style: normal !important;
}

.web-profile-pic .avatar-container {
  height: 100px !important;
  width: 100px !important;
}

.web-profile-pic .avatar-content {
  height: 100px !important;
  width: 100px !important;
  display: flex;
  align-items: center;
  justify-content: center;
}

/*----------------------------------------*/

.ts-tree-img-b .avatar-container {
  height: 90px !important;
  width: 90px !important;
}

.ts-tree-img-b .avatar-content {
  height: 90px !important;
  width: 90px !important;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ts-r-img-block .avatar-container {
  height: 140px !important;
  width: 140px !important;
  margin: 0 auto 20px;
}

.ts-r-img-block .avatar-content {
  height: 140px !important;
  width: 140px !important;
  display: flex;
  align-items: center;
  justify-content: center;
}

/*--------------------------------------*/

.search_team {
  .mat-form-field {
    width: 100%;
  }

  .mat-form-field-wrapper,
  .mat-form-field-appearance-fill .mat-form-field-flex {
    padding: 0 !important;

    input {
      padding-left: 10px !important;
    }
  }

  .mat-form-field-appearance-fill .mat-form-field-label {
    top: 2.09375em !important;
    margin-top: 0 !important;
    left: 10px !important;
  }

  .mat-form-field-appearance-fill .mat-form-field-underline::before,
  .mat-form-field-underline,
  .mat-form-field-ripple {
    background: transparent !important;
  }

  .mat-form-field-prefix,
  .mat-form-field-suffix {
    bottom: 10px !important;
  }

  .mat-form-field-appearance-fill.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label,
  .mat-form-field-appearance-fill.mat-form-field-can-float .mat-input-server:focus+.mat-form-field-label-wrapper .mat-form-field-label {
    transform: translateY(-0.59375em) scale(0) !important;
  }
}

.mat-datepicker-content .mat-calendar {
  height: 414px !important;
}

.c-breadcrum {
  .xng-breadcrumb-trail {
    margin-bottom: 0 !important;
    color: var(--main-color);
  }

  .xng-breadcrumb-item {
    color: var(--main-color);
  }
}

.cust-arrow {
  position: relative;

  &::after {
    position: absolute;
    content: "";
    background-image: url(/assets/images/form-arrow.png);
    z-index: 0;
    width: 20px;
    height: 20px;
    background-repeat: no-repeat;
    top: 18px;
    right: 10px;
  }
}

.search_btn {
  position: absolute;
  right: 0;
  top: 0px;
  cursor: pointer;
}

.drop-lists {
  // background: rgba(255, 255, 255, 0.9) !important;
  position: relative;
  overflow: initial !important;
  top: 10px;
  box-shadow: none !important;
  background: rgb(255 255 255 / 0%) !important;
}

.drop-lists::after {
  content: "";
  position: absolute;
  right: 10px;
  top: -2px;
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid #fafcff;
  z-index: 99999;
}

.chatBox .cke_top,
.chatBox .cke_contents,
.chatBox .cke_bottom {
  height: 110px !important;
}

.chat-drop-bg {
  background-color: #fff !important;

  button.mat-menu-item:hover {
    border-radius: 0 !important;
    border: none !important;

    img {
      filter: brightness(0) invert(1);
    }
  }
}

.hovPoint {
  cursor: pointer;
}

.chatBox {
  width: 100%;

  .cke_bottom.cke_reset_all {
    display: none !important;
  }
}

.para {
  p {
    margin-bottom: 0 !important;
  }
}

.council-user {
  .avatar-container {
    width: 120px !important;
    height: 120px !important;
  }

  // .avatar-content {
  //   width: 120px !important;
  //   height: 120px !important;
  //   display: flex;
  //   align-items: center;
  //   justify-content: center;
  // }
}

// table tbody tr.i-custom-bg-color:nth-child(odd),
// table tbody tr:nth-child(odd) {
//     background: white !important;
// }
// table tbody tr.i-custom-bg-color:nth-child(even),
// table tbody tr:nth-child(even) {
//     background: white !important;
// }

/* ************************* */

.swal2-popup {
  box-sizing: border-box !important;
  background: radial-gradient(55.04% 70.76% at 84.64% -9.25%,
      rgba(111, 255, 227, 0.5) 0%,
      rgba(111, 255, 227, 0.005) 66.67%),
    radial-gradient(68.34% 101.91% at 100% 93.98%,
      #ffecaa 0%,
      rgba(234, 239, 250, 0) 100%),
    radial-gradient(75.39% 80.27% at 0% -2.09%,
      #aceafe 6.8%,
      rgba(172, 234, 254, 0) 100%),
    #f4f9fd !important;
  border-radius: 20px !important;
  padding: 10px 30px 50px !important;
}

.swal2-icon.swal2-success [class^="swal2-success-line"] {
  background-color: #fff !important;
}

.swal2-icon.swal2-success {
  background: #37ac00 !important;
  border-color: #37ac00 !important;
}

.swal2-icon.swal2-success .swal2-success-ring {
  border: none !important;
}

.swal2-title {
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: clamp(20px, 1.25vw, 24px) !important;
  line-height: 30px !important;
  color: var(--main-color) !important;
  margin: 30px 0 !important;
  word-break: break-word;

  @media (max-width: 767px) {
    margin: 10px 0 !important;
  }
}

.swal2-html-container {
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: clamp(14px, 0.9375vw, 18px) !important;
  line-height: clamp(24px, 1.254vw, 24px) !important;
  color: var(--main-color) !important;
}

.swal2-styled.swal2-confirm {
  border: 1px solid #1d4ed8 !important;
  outline: none !important;
  border-radius: 4px !important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: var(--clamp16) !important;
  color: #fff !important;
  background: linear-gradient(180deg, #3b82f6 0%, #1d4ed8 100%) !important;
  text-align: center;
  min-width: 100px;
  letter-spacing: 0.2px;
}

.swal2-styled.swal2-cancel {
  background-color: transparent !important;
  border: 1px solid var(--btn-outlline-coor) !important;
  border-radius: 4px !important;
  color: var(--btn-outlline-coor) !important;
  font-style: normal !important;
  font-weight: 700 !important;
  font-size: var(--clamp16) !important;
  text-transform: capitalize !important;
}

.swal2-styled.swal2-confirm:focus,
.swal2-styled.swal2-cancel:focus {
  box-shadow: none !important;
}

.swal2-success-circular-line-right,
.swal2-success-fix,
.swal2-success-circular-line-left {
  background-color: transparent !important;
}

/* ************************* */

.captcha-box {
  position: relative;
}

.comment-modify-icons {
  span {
    cursor: pointer;
    font-size: 14px;
    color: var(--main-color);
    margin: 0 10px 0 0;

    &:hover {
      opacity: 0.5;
    }

    .fa-trash-alt {
      color: red;
    }
  }
}

.post_img_commity {
  .owl-carousel .owl-item {
    img {
      width: 10%;
      margin: 0 auto 20px;
      display: block;

      @media screen and (max-width: 767px) {
        width: 80%;
      }
    }
  }
}

.commity_head {
  display: flex;
  align-items: center;

  h4 {
    font-size: clamp(16px, 1.25vw, 24px);
    color: var(--main-color);
    margin: 0px;
    font-weight: 700;
  }

  span {
    font-size: clamp(14px, 0.833vw, 16px);
    color: var(--main-color);
  }

  img {
    width: 50px;
  }
}

.group-left-panal-two {
  ul {
    &::-webkit-scrollbar {
      width: 0.5rem;
    }

    &::-webkit-scrollbar-thumb {
      background: linear-gradient(180deg, #0064e65e 0%, #1637856b 100%);
      box-sizing: border-box;
      box-shadow: inset 0px 10px 10px rgba(255, 255, 255, 0.25);
      border-radius: 3px;
    }
  }
}

.groups-accounts-list {
  .pagination-box {
    margin: 20px 0 20px 0;
    color: var(--main-color);
    font-size: clamp(14px, 0.8333vw, 16px);
    font-weight: 600;

    .ngx-pagination {
      li {
        span {
          color: var(--main-color);
        }

        &.current {
          background: linear-gradient(180deg,
              rgba(255, 255, 255, 0.1) 0%,
              rgba(255, 255, 255, 0) 100%),
            linear-gradient(180deg, rgba(255, 219, 0, 0.8) 0%, #ff9241 98.96%);
          color: #fff;
        }

        button,
        a {
          color: var(--color2);
        }
      }
    }
  }
}

.community-page-title {
  font-size: clamp(24px, 2.5vw, 48px);
  color: var(--main-color);
  margin: 0px;
  font-weight: 700;
}

.comment-replay-block {
  padding: 0;

  .comment-replay-inner-block {
    .comment-box {
      width: 90%;
      clear: both;

      &:nth-child(even) {
        float: right;
        width: 85%;
        background: rgba(254, 245, 214, 1) !important;
        margin: 0 15px 0 0;
      }

      &:nth-child(odd) {
        float: left;
        width: 85%;
        background: rgba(248, 230, 255, 1) !important;
        margin: 0 0 0 15px;
      }
    }
  }
}

// to hide the arrows for the type Number
// Chrome, Safari, Edge, Opera
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

// Firefox
input[type="number"] {
  -moz-appearance: textfield;
}

.login-user-icon {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0px;
}

.go-login-inn {
  @media screen and (max-width: 1024px) {
    height: 100%;
  }

  .flow_btns {
    display: flex;
    margin: 4rem 0 0;
    justify-content: space-between;

    @media screen and (max-width: 1440px) {
      display: flex;
      margin-top: 50px;
      justify-content: space-between;
      text-align: center;
    }

    @media screen and (max-width: 380px) {
      margin-top: 20px;
    }

    button {
      color: var(--main-color);
      font-size: var(--clamp16);
      text-transform: capitalize;
      margin: 0;
      margin-bottom: 8px;
      font-weight: 400 !important;
      justify-content: center;
      padding: 6px 20px !important;

      &:first-child {
        flex: 1;
      }

      &:not(:last-child) {
        margin-right: 12px;

        @media screen and (max-width: 991px) {
          margin-right: 0px;
        }
      }

      @media screen and (min-width: 1440px) {
        padding: 9px 24px !important;
      }

      @media screen and (max-width: 1366px) {
        padding: 9px 12px !important;
        margin-bottom: 5px !important;
        min-width: auto;
        font-size: 14px;
      }

      @media screen and (max-width: 1200px) {
        padding: 7px 15px !important;
      }

      @media screen and (max-width: 1024px) {
        padding: 9px 9px !important;
        font-size: 14px !important;
        margin: 0 0;
      }

      @media screen and (max-width: 992px) {
        padding: 8px 10px !important;
        margin-bottom: 16px !important;
        flex-grow: 1;
      }

      &:focus {
        box-shadow: none;
      }
    }
  }

  .mat-form-field {
    .mat-form-field-wrapper {
      padding-top: 7px;

      @media screen and (min-width: 1440px) {
        margin-top: 15px;
      }

      @media screen and (max-width: 991px) {
        padding-bottom: 6px;
      }

      .mat-form-field-flex {
        .mat-form-field-infix {
          top: 3px;
          line-height: 12px;
          padding-right: 26px !important;

          @media screen and (min-width: 1440px) {
            padding: 16px 0px 14px;
          }

          @media screen and (max-width: 1400px) {
            top: 5px;
          }

          @media screen and (max-width: 1280px) {
            top: 1px;
          }

          .mat-form-field-label-wrapper {
            span {
              color: #e84c3d;
            }

            .mat-form-field-label {
              @media screen and (max-width: 1440px) {
                margin-top: 2px;
              }
            }

            @media screen and (min-width: 1440px) {
              top: -5px;
            }
          }
        }

        .mat-form-field-suffix {
          color: #7688c2;
          cursor: pointer;
        }
      }

      .mat-form-field-subscript-wrapper {
        font-size: var(--clamp16);
        padding: 0;
      }
    }

    &.pwd-field {
      .mat-form-field-wrapper {
        margin-bottom: 0px;
        padding-bottom: 0px;
      }
    }
  }

  .container {
    @media screen and (min-width: 1440px) {
      max-width: 1450px;
    }

    @media screen and (min-width: 1430px) and (max-width: 1440px) {
      max-width: 1294px;
    }

    @media screen and (max-width: 1024px) {
      height: 100%;
    }

    .login-main-content {
      @media screen and (max-width: 1024px) {
        height: 100%;
      }
    }
  }

  .password-field {
    .pwd-field {
      .mat-form-field-wrapper {
        margin-bottom: 62px;
        padding-bottom: 0px;

        @media screen and (max-width: 1366px) {
          margin-bottom: 20px;
        }
      }
    }
  }
}

.go-logins {

  // .container {
  //     max-width: 1375px;
  // }
  .captcha-btn {
    background: #fff;
    border-radius: 4px;
    margin-top: -1px;
  }

  .captcha-wrap {
    border: 1px solid #cdd1e1;
    // border: 1px solid rgba(255, 255, 255, 0.1);
    padding: 10px;
    border-radius: 4px;
    width: 97% !important;
    margin-left: 0px;
    align-items: center;
    margin-bottom: 20px;
    margin-top: 10px;

    @media screen and (max-width: 767px) {
      padding: 5px;
    }

    @media screen and (max-width: 767px) {
      text-align: center;
    }
  }

  .re-capcha {
    color: rgba(28, 58, 169, 0.5);

    a {
      font-size: 10px;
      color: #163885;
    }
  }

  .remember-me {
    width: 18px;
    height: 18px !important;
    top: -4px;
  }

  .capcha-position {
    position: relative;

    .err-txt {
      position: absolute;
      bottom: -33px;
      color: var(--error-color) !important;
    }
  }
}

.err-txt {
  color: var(--error-color) !important;
}

.mat-form-field-hide-placeholder {
  .mat-form-field-wrapper {
    .mat-form-field-flex {
      .mat-form-field-outline {
        color: #7688c2;
        height: 48px;

        @media screen and (max-width: 1366px) {
          height: 44px;
        }

        @media screen and (max-width: 1024px) {
          height: 42px;
        }

        @media screen and (max-width: 991px) {
          height: 40px;
        }
      }
    }
  }
}

.go-login-inn {
  .mat-error {
    font-size: 12px;
  }
}

.mat-form-field {
  font-family: "Open Sans", sans-serif !important;
}

.captcha-box {
  position: inherit;
}

.mat-form-field-subscript-wrapper {
  position: relative !important;
  margin-top: 5px;
}

.title-block {
  margin-bottom: 20px;

  @media (max-width: 767px) {
    margin-bottom: 20px;
  }

  h2 {
    font-style: normal;
    font-weight: 600;
    font-size: var(--clamp24);
    color: var(--main-color);
    margin-bottom: 0;

    @media (max-width: 767px) {
      margin-bottom: 20px;
    }
  }

  .btn-links {
    a {
      display: inline-block;

      &:not(:last-child) {
        margin-right: 15px;

        @media screen and (max-width: 320px) {
          margin-bottom: 15px;
        }
      }
    }
  }
}

.comingsoon-page {
  .img-wrap {
    img {
      width: 50%;
      margin: auto;
      display: flex;
      padding: 3rem 0;
    }
  }
}

.custom_create_url_div .build_brand_txt {
  display: none;
}

.custom_create_url_div .trimurl_top_container {
  // background: #ffffff;
  // background: linear-gradient(180deg, #78C8FD 15.77%, #18A0FB 100%);
  background: #d1fae5;
  padding: 24px;
  box-shadow: 4px 4px 10px rgb(67 127 236 / 10%);
  border-radius: 4px;

  @media screen and (max-width: 640px) {
    padding: 24px 15px;
  }
}

.title_back_round h3 p {
  margin-bottom: 0;
  font-size: var(--clamp14);
  font-weight: normal;
  line-height: 22px;
}

.raise-form-content .mat-drawer-container {
  color: var(--main-color);
  font-weight: 600;
  font-size: var(--clamp16);
  line-height: 22px;
}

// srinivas styles 12/10/2021
.create_ul_radio .mat-radio-inner-circle {
  background-color: var(--main-color) !important;
}

.create_ul_radio .mat-radio-outer-circle {
  border-color: var(--main-color) !important;
}

.trim_btn {
  height: 48px !important;
  // background: linear-gradient(187.33deg, #4285F4 5.01%, #1C3AA9 57.51%);
  // color:#ffffff;
  // min-width:90px;
}

.trim_input {
  height: 48px;
  width: calc(100% - 123px);

  @media screen and (max-width: 640px) {
    width: calc(100% - 100px);
  }
}

.custom_url_input {
  height: 48px;
  width: 100%;
  border: 1px solid #a7c0f5 !important;
  padding: 10px;
  border-radius: 4px;
}

// .custom_track{
//     background: linear-gradient(187.33deg, #4285F4 5.01%, #1C3AA9 57.51%);
// }
.alias_search_input .mat-form-field .mat-form-field-flex {
  height: 48px;
  border: 1px solid #a7c0f5 !important;
  padding: 0px 10px;
  background: #ffffff !important;
  border-radius: 4px;
}

.alias_search_input .mat-form-field .mat-form-field-infix {
  padding: 10px 0px;
}

.alias_search_input .mat-form-field .mat-form-field-underline {
  display: none;
}

.alias_search_input .mat-form-field .mat-form-field-infix input {
  background: transparent !important;
  margin: 0px;
  height: 100%;
}

.modal-header {
  background: #0199ff;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.url_list_single .mat-form-field .mat-form-field-flex {
  padding: 0px 10px !important;
  border: 1px solid #a7c0f5 !important;
  height: 48px !important;
  border-radius: 4px;
  box-sizing: border-box;
  background: #ffffff !important;
}

.url_list_single .mat-form-field .mat-form-field-underline {
  display: none;
}

.url_list_single .mat-form-field .mat-form-field-wrapper {
  padding-bottom: 0px;
}

.url_list_single .mat-form-field .mat-form-field-infix {
  padding: 10px 0px;
}

.url_list_single div button.url_single_btn {
  height: 48px;
  cursor: pointer;
  border-radius: 4px;
  box-shadow: none;
  background: #ffffff;
  border: 1px solid #7688c2;
  box-sizing: border-box;
}

.trimurl_collapse_arrow {
  position: absolute;
  right: 42px;
  top: 10px;
  cursor: pointer;
}

.trimurl_collapse_checkbox label {
  opacity: 0 !important;
  width: 30px !important;
  height: 30px !important;
}

.rowbtns {
  line-height: 19px;
  height: 44px;
  padding: 8px 12px !important;
  display: inline-flex;
  align-items: center;
  background: rgba(255, 255, 255, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.5);
  box-sizing: border-box;
  border-radius: 4px;
  color: #ffffff;
  text-transform: inherit;
  font-weight: 400;
}

.rowbtns img {
  width: 20px;
  margin-right: 10px;
  filter: invert(100%) sepia(100%) saturate(50%) hue-rotate(360deg) brightness(108%) contrast(1000%);
}

.updateCloseButton {
  height: 30px;
  font-weight: 700;
  padding: 0rem 1rem;
  // margin: -1rem -1rem -1rem auto;
  background: red;
  text-shadow: none;
  color: #fff;
  // position: absolute;
  z-index: 100;
  width: 30px;
  // right: 5px;
  border-radius: 26px;
  border: none;
  // top: 5px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: -23px;
  right: -23px;

  @media screen and (max-width: 640px) {
    right: 0;
    top: -30px;
  }
}

.modal_cancel_btn {
  padding: 12px 24px;
  height: 52px;
  border: 1px solid #0a2477;
  color: var(--main-color);
  border-radius: 4px;
  cursor: pointer;
}

.qrcode_div .qrcode-block .qrcode canvas {
  width: 120px !important;
  height: auto !important;
}

.scanText {
  font-size: 10px !important;
  padding: 0px 14px;
}

.url_list_single .mat-form-field .mat-form-field-flex .mat-form-field-label-wrapper {
  top: -9px !important;
}

.url_list_single .mat-form-field .mat-form-field-infix input {
  height: 100% !important;
}

.url_list_single .mat-form-field .mat-form-field-label {
  background: #ffffff;
  width: auto !important;
  border-radius: 3px;
}

.url_list_single .mat-form-field mat-label {
  padding: 3px 8px 3px 6px;
  font-weight: bold;
}

.url_list_single .mat-form-field-should-float mat-label {
  font-size: 18px;
}

.alias_search_input .mat-form-field-wrapper {
  padding-bottom: 0px !important;
}

.mat-paginator-range-actions {
  margin-top: -13px;
}

.create_ul_radio .mat-focus-indicator {
  display: none !important;
}

.img_div .slide,
.img_div .slick-track,
.img_div .slick-list {
  width: 100% !important;
}

//theme styles start

/* custom buttons start  */

.custom-btn-outline {
  box-sizing: border-box;
  border-radius: 4px;
  padding: 0px 12px !important;
  height: 44px;
  // line-height: 44px;
  align-items: center;
  text-transform: capitalize !important;
  font-size: var(--clamp16);
  font-weight: 600;
  text-decoration: none !important;
  cursor: pointer !important;
  position: relative;
  background: #fff;
  min-width: 90px;
  text-align: center;
  display: inline-flex;
  justify-content: center;
}

@media screen and (max-width: 1366px) {
  .custom-btn-outline {
    height: 42px;
    line-height: 42px;
  }
}

@media screen and (max-width: 1024px) {
  .custom-btn-outline {
    height: 40px;
    line-height: 40px;
  }
}

@media screen and (max-width: 991px) {
  .custom-btn-outline {
    height: 38px;
    line-height: 38px;
  }
}

.custom-btn-outline:hover {
  text-decoration: none;
  color: var(--main-color);
}

.custom-btn-fill,
.active-btn-bg {
  box-sizing: border-box;
  border-radius: 4px;
  color: rgb(255, 255, 255) !important;
  padding: 0px 12px !important;
  height: 44px;
  line-height: 44px;
  display: inline-flex !important;
  align-items: center;
  text-transform: capitalize !important;
  font-size: var(--clamp16) !important;
  font-weight: 600;
  text-decoration: none !important;
  cursor: pointer !important;
  position: relative;
  overflow: hidden;
  text-align: center !important;
  justify-content: center;
  min-width: 90px;
}

.custom-btn-close {
  height: 44px;
  line-height: 44px;
  padding: 0 12px;
  min-width: 90px;
  background: linear-gradient(180deg, #F1F6FF 7.29%, #CBDDFF 53.12%);
  border: 1px solid #A7C0F5;
  border-radius: 4px;
  color: var(--main-color);
  font-weight: 500;
}

.custom-btn-fill-sm-btn,
.active-btn-bg-sm-btn {
  padding: 5px 15px !important;
  box-sizing: border-box;
  border-radius: 4px;
  color: var(--of-white) !important;
  font-size: var(--clamp16) !important;

  @media screen and (max-width: 640px) {
    padding: 0px 15px !important;
  }
}

@media screen and (max-width: 1366px) {

  .custom-btn-fill,
  .active-btn-bg {
    height: 42px;
    line-height: 42px;
  }
}

@media screen and (max-width: 1024px) {

  .custom-btn-fill,
  .active-btn-bg {
    height: 40px;
    line-height: 40px;
  }
}

@media screen and (max-width: 992px) {

  .custom-btn-fill,
  .active-btn-bg {
    height: 38px !important;
    line-height: 38px !important;
  }
}

.custom-btn-fill:hover:before,
.custom-btn-outline:hover:before {
  opacity: 1;
}

.custom-btn-fill:active:after,
.custom-btn-outline:active:after {
  transform: scale(0, 0);
  opacity: 0.3;
  transition: 0s;
}

.custom-btn-fill:hover {
  text-decoration: none;
  color: var(--main-color);
}

.custom-btn-outline img,
.custom-btn-fill img {
  margin-right: 6px;
  width: 22px;
  position: relative;
  z-index: 1;
}

/* custom buttons end  */

/* nav pills css start   */
.mat-tab-labels {
  margin-bottom: 15px;
  border-bottom: 1px solid #b8d5e9;
}

.eml-tmp-btn {
  padding-bottom: 10px;

  .mat-tab-label {
    color: var(--color6) !important;
    font-size: var(--clamp16);
    min-width: 100px;
    text-align: center;
    opacity: 1;
    font-weight: normal;
    padding: 0.5rem 16px !important;
    position: relative;
    margin: 0 10px 16px 0;
    border: none;
    border-radius: 4px;
    box-shadow: none !important;
    height: 42px;
    overflow: visible;

    // @media screen and (min-width: 1400px) {
    //   margin: 0px 8px 6px 0px !important;
    // }
    &:hover {
      background: #b1e0ff !important;
    }

    &-active {
      background: #b1e0ff !important;
      position: relative;
      box-shadow: none !important;

      &::after {
        position: absolute;
        content: "";
        width: 100%;
        height: 4px;
        background: #fe7a00;
        bottom: -16px;
        z-index: 2;
        left: 0;
        border-radius: 4px 4px 0px 0px;
      }

      &:hover {
        background: lighten($linkcolour, 30%) !important;
      }
    }

    .mat-ripple-element {
      display: none !important;
    }
  }
}

.nav-pills {
  margin-bottom: 15px;
  border-bottom: 1px solid #b8d5e9;

  .nav-item {
    padding-bottom: 10px;

    .nav-link {
      color: var(--color6) !important;
      font-size: var(--clamp16);
      min-width: 100px;
      text-align: center;
      opacity: 1;
      font-weight: normal;
      padding: 0.6rem 16px !important;
      position: relative;
      margin: 0px 5px 6px 0px !important;
      border-radius: 4px;
      box-shadow: none !important;

      @media screen and (min-width: 1400px) {
        margin: 0px 8px 6px 0px !important;
      }

      &:hover {
        background: #b1e0ff !important;
      }

      &.active {
        background: #b2e0ff !important;
        text-shadow: 0 0 var(--color6);
        position: relative;
        color: var(--main-color) !important;

        &::after {
          position: absolute;
          content: "";
          width: 100%;
          height: 4px;
          background: #fe7a00;
          bottom: -16px;
          z-index: 2;
          left: 0;
          border-radius: 4px 4px 0px 0px;

          @media screen and (max-width: 992px) {
            bottom: -8px;
          }
        }

        &:hover {
          background: lighten($linkcolour, 30%) !important;
        }
      }
    }

    &:last-child {
      .nav-link {
        margin-right: 0;
      }
    }
  }
}

@media screen and (max-width: 768px) {

  .nav-pills .nav-link,
  .eml-tmp-btn .mat-tab-label {
    font-size: var(--clamp16);
    padding: 5px;
  }
}

/* nav pills css end   */

/* tables custom styles  */

.table:not(.table-condensed) thead tr {
  background: var(--bg-color2);
  color: white;
  border: 0;
}

.table:not(.table-condensed):not(.cke_dialog):not(.cke_dialog_ui_hbox.cke_dialog_footer_buttons) tbody tr td {
  font-size: clamp(14px, 0.8333vw, 16px) !important;
  border-top: 4px solid #fff !important;
  vertical-align: middle !important;
}

.table:not(.table-condensed) thead tr th:not(:first-child),
.table:not(.table-condensed) tbody tr td:not(:first-of-type) {
  padding-left: 5px !important;
  padding-right: 5px !important;
}

.table:not(.table-condensed) tbody tr:nth-child(odd),
.table:not(.table-condensed) tbody tr:nth-child(odd) {
  background: #effcff !important;
}

.table:not(.table-condensed) tbody tr:nth-child(even),
.table:not(.table-condensed) tbody tr:nth-child(even) {
  background: #ecfff5 !important;
}

mat-row,
mat-header-row,
mat-footer-row,
th.mat-header-cell,
td.mat-cell,
td.mat-footer-cell {
  border-bottom-color: rgb(0 0 0 / 0%) !important;
}

.table:not(.table-condensed) thead tr {
  background: var(--bg-color2);
  color: white;
}

.table:not(.table-condensed) thead tr th {
  border-bottom: 0px solid #dee2e6 !important;
  font-weight: 500;
  vertical-align: middle;
  white-space: nowrap;
}

/* tables custom styles end  */

/* ebook page  */

.ebooktopsec {
  background-color: #f3fff9;
}

.e_book_bann {
  background-color: #fff;
}

//theme styles  end
.close-btn,
.modal-header .close {
  width: 28px;
  height: 28px;
  background-color: #e84c3d;
  border-radius: 100%;
  color: #fff;
  padding: 0;
  line-height: 30px;
  display: inline-block;
  position: absolute;
  right: -22px;
  top: -22px;
  outline: none;
  box-shadow: none;
  border: none;
  z-index: 10;
  font-weight: normal;
  opacity: 1 !important;

  @media screen and (max-width: 640px) {
    // position: relative;
    top: -32px;
    right: 0;
  }

  i {
    font-size: var(--clamp18);
    font-weight: 100;
  }
}

.cke_dialog_body,
.cke_combo_on a.cke_combo_button {
  background: rgb(255 255 255) !important;
  border-color: rgb(255 255 255) !important;
}

a:hover.cke_colorauto,
a:hover.cke_colormore,
a:active.cke_colorauto,
a:active.cke_colormore {
  background-color: transparent !important;
  border-color: transparent !important;
}

.cke_resizer {
  border-right-color: #b5b5b5 !important;
}

.cke_dialog_tab.cke_dialog_tab_selected {
  background-color: #64c1ff !important;
}

.cke_1.cke_panel {
  border-color: #fff !important;
}

.cke_dialog_title,
.cke_dialog_tab {
  background-color: var(--bg-color2) !important;
  border-bottom-color: var(--bg-color2) !important;
  color: #ffffff !important;
}

table.cke_reset_all table tbody td {
  border-top: 0px solid transparent !important;
}

a.cke_dialog_tab {
  color: #ffffff !important;
}

.cke_dialog_contents {
  border-top-color: var(--bg-color2) !important;
}

.cke_dialog_footer {
  background-color: var(--bg-color2) !important;
  outline-color: var(--bg-color2) !important;
  border-top: 0 !important;
}

a.cke_dialog_ui_button {
  background: var(--main-color) !important;
  border: 1px solid var(--main-color) !important;
  color: var(--of-white) !important;
}

a.cke_dialog_ui_button_ok {
  background-color: var(--of-white) !important;
  color: #484848 !important;
  border: 1px solid var(--of-white) !important;
  margin-right: 5px !important;
}

.cke_dialog_ui_hbox {
  background: transparent !important;
}

.cke_dialog_ui_hbox_first,
.cke_dialog_ui_hbox_last {
  border-top: 0 !important;
  padding: 0 !important;
}

.cke_ltr .cke_dialog_close_button {
  opacity: 0 !important;
}

.cke_dialog_ui_input_text,
.cke_dialog_ui_input_select {
  margin: 2px !important;
}

.cke_dialog_body {
  @media screen and (max-width: 414px) {
    margin-left: 10px !important;
    margin-right: 10px !important;
  }

  @media screen and (max-width: 375px) {
    margin-left: 10px !important;
    margin-right: 10px !important;
  }
}

.cke_dialog .ImagePreviewBox {
  @media screen and (max-width: 414px) {
    width: 260px !important;
  }

  @media screen and (max-width: 375px) {
    width: 230px !important;
  }
}

.chart_div svg defs {
  display: none;
}

.chart_div svg {
  padding-top: 20px;
}

.chart_div div:first-child {
  overflow: visible !important;
}

.no-records {
  opacity: 0.4;
  cursor: not-allowed !important;
}

.edit-delte-dd {
  min-height: auto !important;
  min-width: auto !important;
}

.edit-delte-dd .mat-menu-content {
  padding: 0px !important;
}

.camera-video-modal .modal-body {
  background-color: var(--bg-color3) !important;
  border-radius: 15px;
  padding: 24px;
  text-align: center;
  box-shadow: 0px 0px 100px #888;
}

.camera-video-modal .modal-body .video-wrapper {
  border: 1px solid var(--main-color);
  border-radius: 4px;
  background-color: var(--of-white);
  width: 100%;
  position: relative;
}

.camera-video-modal .modal-dialog {
  position: relative;
  top: -20px;
}

a.cke_smile img {
  width: 20px !important;
}

a.cke_smile:hover,
a.cke_specialchar:hover,
a.cke_smile:active,
a.cke_smile:focus {
  border-color: var(--color3) !important;
  border-radius: 3px;
}

.agree-date input.form-control {
  border: 1px solid var(--color5);
}

.nda_div signature-pad canvas {
  position: absolute;
  height: 100% !important;
  // width:100% !important;
  top: 0;
}

.mat-tab-header-pagination {
  box-shadow: none;
}

@media screen and (max-width: 574px) {

  .modal-content .close-btn,
  .pop-up button.btn-primary {
    width: 20px !important;
    height: 20px !important;
    line-height: 22px !important;
    right: 10px !important;
    top: 10px !important;
    position: absolute !important;
  }
}

// breadcrum styles start
.custom-breadcrum {
  background: rgba(255, 255, 255, 0.2) !important;
  border: 1.5px solid rgba(255, 255, 255, 0.8) !important;
  box-shadow: inset 0px 10px 20px rgb(255 255 255 / 80%) !important;
  backdrop-filter: blur(30px);
  box-sizing: border-box;
  border-radius: 4px;
  padding: 9px 8px;

  ul {
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    margin: 0;
    padding: 0;
    align-items: center;

    li {
      color: var(--main-color);
      line-height: normal;

      &:after {
        content: "\f105";
        font-family: "Font Awesome 5 Pro", sans-serif;
        color: #334272;
        font-size: 18px;
        padding: 0 18px;
        position: relative;
        top: 1px;

        @media screen and (max-width: 640px) {
          padding: 0 10px;
        }
      }

      &:last-child {
        &::after {
          display: none;
        }
      }

      a {
        color: var(--main-color);
      }
    }
  }
}

// breadcrum styles end

.pwd-icon {
  position: absolute;
  right: 0;
  top: 45%;
  transform: translateY(-50%);
  color: #7688c2;
  text-decoration: none !important;
}

// my links password styles
.password-block {
  position: relative;

  .password-icon {
    position: absolute;
    right: 15px;
    top: 50%;
    transform: translateY(-50%);
    outline: none;
    font-size: 18px;
    border: none;

    &:focus {
      border: none;
    }
  }
}


.db-top-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: var(--ob-title-bg);
  border-radius: 4px;
  padding: 12px 20px;

  @media (max-width: 991px) {
    padding: 10px 12px;
  }

  .title {
    h4 {
      margin: 0;
      color: var(--main-color);
      font-weight: 600;
      font-size: var(--clamp18);
      line-height: 25px;

      @media (max-width: 767px) {
        font-size: 16px;
      }

      a {
        color: rgba(10, 36, 119, 0.5);
        font-weight: 500;
        text-decoration: none;
        font-weight: 400;
      }
    }
  }
}

.cus-input-group {
  input {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  button {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    background: linear-gradient(180deg, #3B82F6 0%, #1D4ED8 100%);
    color: #fff;
    border: 1px solid var(--input-border);
    border-left: 0;

    &:focus {
      box-shadow: 0px 0px 3px 1px var(--of-focus-color) !important;
      outline: none;
    }
  }
}

.cus-input-search {
  position: relative;

  input {
    padding-right: 40px;
    height: 42px;
    background: #FFFFFF;
    border: 1px solid var(--input-border);
    border-radius: 4px;
  }

  @include placeholder {
    color: var(--ob-placeholder) !important;
  }

  button {
    position: absolute;
    right: 15px;
    top: 50%;
    transform: translateY(-50%);
    background: none;
    box-shadow: none;
    outline: none;
    border: none;
    color: var(--main-color);
    height: 42px;
    font-size: 16px;
  }
}

.cus-selct.form-control {
  height: 42px;
  background: #FFFFFF;
  border: 1px solid var(--input-border);
  border-radius: 4px;
  color: var(--main-color);
  font-size: var(--clamp16);
}

.badge-date {
  position: absolute;
  right: 0px;
  top: -7px;
  width: 80px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media(min-width: 1024px) {
    width: 100px;
    top: -5px;
  }

  img {
    width: 100%;
  }

  .days_left_txt {
    width: 100%;
    padding: 6px 17px 18px 16px;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  h5 {
    color: var(--of-white);
    font-size: var(--clamp18);
    margin-bottom: 0;
    font-weight: 500;
    line-height: 14px;
    font-size: 24px;

    span {
      color: var(--of-white);
      text-transform: uppercase;
      font-size: 13px;
      display: inline-block;
      line-height: 14px;
      margin-top: 6px;
      font-weight: 400;
    }
  }


}


.table-wrapper {
  .table-responsive {
    table.table.mat-table {
      background: var(--of-white);
      border: 1px solid #b4d5ff;
      border-radius: 4px;
      border-spacing: 0 !important;

      thead {
        vertical-align: middle;

        tr {
          &.mat-header-row {
            height: 52px;
          }

          th {
            background: #CADCFF;
            color: #0A4BA2 !important;
            font-size: var(--clamp16);
            font-weight: 500;
            letter-spacing: 0.4px;
            border: none;

            &:first-child {
              border-top-left-radius: 4px;
              min-width: 80px;
            }

            &:last-child {
              border-top-right-radius: 4px;
            }

            &:not(:first-child) {
              padding-left: 12px;
            }

            strong {
              font-weight: 500;
            }
          }
        }
      }

      tbody {
        border: none;

        tr.mat-row {
          &.mat-row {
            // height: 52px;
          }

          td {
            vertical-align: middle !important;
            border-top: 4px solid var(--of-white) !important;
            background: #ecfff5;
            color: #094BA2 !important;
            font-size: var(--clamp16) !important;
            font-weight: 400;
            padding-top: 8px;
            padding-bottom: 8px;
            border-radius: 0;

            &:not(:first-child) {
              padding-left: 12px;
            }
          }

          &:nth-child(even) td {
            background: #effcff;
          }
        }
      }
    }
  }
}

.custom-btn-fill.green-btn {
  background: linear-gradient(180deg, rgba(10, 209, 42, 0.99) 0%, #008400 100%);
  border: 1px solid rgba(10, 209, 42, 0.99);
}

.td-max-width {
  max-width: 150px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.mat-paginator {
  background: transparent !important;

  .mat-paginator-container {
    font-size: var(--of-clamp16);

    .mat-paginator-page-size {
      color: var(--of-main-text-color);

      .mat-paginator-page-size-label {
        font-size: var(--of-clamp16);
      }

      .mat-form-field {
        .mat-select {

          // .mat-option{
          //   font-size: var(--of-clamp14);
          // }
          .mat-select-trigger {
            .mat-select-value {
              .mat-select-value-text {
                color: var(--of-main-text-color);
                font-size: var(--of-clamp16);
              }
            }
          }

          .mat-select-arrow-wrapper {
            .mat-select-arrow {
              color: var(--of-main-text-color);
            }
          }
        }

        .mat-form-field-wrapper {
          .mat-form-field-underline {
            display: none;
          }
        }
      }
    }

    .mat-paginator-range-actions {
      .mat-paginator-range-label {
        color: var(--of-main-text-color);
        font-size: var(--of-clamp16);
      }

      .mat-icon-button {
        color: var(--of-main-text-color);

        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 7px;
        font-weight: 400;
        background: #fff;
        box-shadow: 2px 2px 2px #ccc;

        &.mat-button-disabled {
          opacity: 0.3;
        }
      }
    }
  }
}


// obless image show modal styles start
.modal-type-one {
  .modal-content {
    background-color: transparent !important;
    border: none;
    width: fit-content;
    margin: 0 auto;
  }

  .modal-body {
    position: relative;

    .close-btn {
      position: absolute;
      border: none;
      box-shadow: none;
      outline: none;
      color: var(--of-white);
      font-size: 24px;
      width: 30px;
      height: 30px;
      font-weight: 500;
      border-radius: 100%;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      opacity: 1;
      right: -24px;
      top: -24px;
      background: #f00;

      &:hover {
        opacity: 3;
        color: var(--of-white);
      }
    }

    img {
      max-height: 70vh;
      // object-fit: contain;
      border: 4px solid var(--of-white);
      border-radius: 4px;
      min-width: 400px;
    }
  }
}


// bottom space for obless layout getting bottom space in iphone and tabs
.preview-campaign,
.donar-preview,
.create-campaign,
.my-campaigns,
.my-donations {
  padding-bottom: 100px;
}

.all-campaigns,
.my-campaigns,
.my-donations {

  input.form-control,
  select,
  textarea,
  .focus,
  .custom-btn-fill {

    &:focus,
    &:focus-visible {
      box-shadow: 0px 0px 3px 1px var(--of-focus-color) !important;
      outline: none;
    }
  }
}

// media gallery slick slider styles start

.media-library {
  .modal-header {
    height: 0;
  }

  .slider-for {
    max-width: 640px;
    margin: 15px auto;
    padding: 0 40px;
    position: relative;

    .slick-arrow {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      background-color: rgba(1, 153, 255, 0.15);
      box-shadow: none;
      border: none;
      width: 40px;
      height: 40px;
      color: #fff;
      font-size: 22px;
      color: var(--of-main-color);
      border-radius: 100%;

      &:hover {
        box-shadow: 0 0 10px #cdcdcd;
      }

      &.slick-prev {
        left: -10px;

        @media screen and (max-width: 640px) {
          left: 15px;
        }
      }

      &.slick-next {
        right: -10px;

        @media screen and (max-width: 640px) {
          right: 15px;
        }
      }
    }

    .slick-list {
      .slick-slide {
        height: 400px;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
          object-fit: contain;
          width: 100%;
          height: 100%;
        }
      }
    }
  }

  .slider-nav {
    padding-bottom: 15px;

    .slick-list {
      .slick-slide {
        height: 90px;

        img,
        video {
          object-fit: cover;
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}

// media gallery slick slider styles end

// frola editor styles customization start
.fr-quick-insert.fr-visible {
  left: 100px !important;
}

.custom-error-message {
  color: red;
  font-size: 14px;
  padding: 5px 6px;
  background: #e6e5db;
}

.fr-popup.fr-desktop.fr-active {
  z-index: 30 !important;
}

button[data-cmd=imageManager] {
  display: none !important;
}
.fr-box .fr-toolbar .fr-command.fr-btn.fr-open{
  margin-top: 4px;
}
.fr-view{
  ol, ul{
    padding-left: 2rem;
  }
}
// frola editor styles customization end

//header google translater css start
.translated-rtl,  .translated-ltr{
  body{
      margin-top: 0 !important;
      top: 0!important;
  }

    .skiptranslate {
        .skiptranslate{
        display: none !important;
        }
    }
  }

  .VIpgJd-ZVi9od-aZ2wEe-wOHMyf{
    display: none !important;
  }


.skiptranslate {
    .skiptranslate{
      display: none !important;
    }
}
